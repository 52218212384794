import { projectDatabase, projectAuth } from "@/firebase/config";
import rulesJSON from "./rules.json";

export default () => {
  const can = async (rule) => {
    const user = await projectAuth.currentUser.getIdTokenResult();
    const role = user.claims.role;
    const res = await projectDatabase.ref("users/roles").get();
    const roles = res.val();
    return !(!roles[role].rules || !roles[role].rules[rule]);
  };

  const getRules = async () => {
    const rules = [];
    Object.values(rulesJSON).map((r) => {
      rules.push(r.id);
    });
    const promises = [];
    const responses = [];
    const result = {};
    rules.map((r) => {
      promises.push(can(r));
      responses.push(r);
    });
    let results = await Promise.all(promises);
    responses.forEach((res, i) => {
      result[res] = results[i];
    });
    return result;
  };

  return { can, getRules };
};
