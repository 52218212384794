<template>
  <div class="grid" v-if="menu.length">
    <ContextCard
      header="Inventaires"
      :resource="boards"
      :loading="boardsPending"
      :links="boardsLinks"
    />
    <ContextCard
      header="Mercuriales"
      :resource="mercurial"
      :loading="mercurialPending"
      scheme="orange"
      icon="fa fa-list-alt"
      :links="mercurialLinks"
    />
    <ContextCard
      header="Mercuriales Easilys"
      :resource="easilysMercurial"
      :loading="easilysMercurialPending"
      scheme="gray"
      icon="fa fa-list-alt"
      :links="easilysMercurialLinks"
    />
    <ContextCard
      header="Etablissements"
      :resource="centers"
      :loading="centersPending"
      scheme="cyan"
      icon="fa fa-city"
      :links="centersLinks"
    />
    <ContextCard
      header="Utilisateurs"
      :resource="users"
      :loading="usersPending"
      scheme="green"
      icon="fa fa-users"
      :links="usersLinks"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useDatabase from "../../composables/useDatabase";
import { projectFunctions } from "@/firebase/config";
import getMenu from "../../layouts/_menu.js";

import ContextCard from "./_ContextCard";

export default {
  components: { ContextCard },
  setup() {
    // Boards
    const { docs: boards, pending: boardsPending } =
      useDatabase("boards").getAll();
    const boardsLinks = ref([
      { label: "Exports", to: { name: "BoardsList" } },
      { label: "Automatisations", to: "/", disabled: true },
    ]);

    // Centers
    const { docs: centers, pending: centersPending } =
      useDatabase("centers").getAll();
    const centersLinks = ref([
      { label: "Etablissements", to: { name: "CentersList" } },
    ]);

    // Articles
    const { docs: mercurial, pending: mercurialPending } =
      useDatabase("mercurial/articles").getAll();
    const mercurialLinks = ref([
      { label: "Liquide", to: { name: "Mercurial" } },
    ]);

    // Articles Easilys
    const { docs: easilysMercurial, pending: easilysMercurialPending } =
      useDatabase("mercurial-easilys/articles").getAll();
    const easilysMercurialLinks = ref([
      { label: "Liquide", to: { name: "EasilysMercurial" } },
    ]);
    const menu = ref([]);
    // Users
    onMounted(async () => {
      await listUsers();
      menu.value = await getMenu();
    });
    const usersPending = ref(false);
    const users = ref([]);
    const listUsers = async () => {
      usersPending.value = true;
      const res = await projectFunctions.httpsCallable("listUsers").call(null);
      users.value = res.data;
      usersPending.value = false;
    };
    const usersLinks = ref([
      { label: "Utilisateurs", to: { name: "UsersList" } },
    ]);

    return {
      boards,
      boardsPending,
      boardsLinks,
      centers,
      centersPending,
      centersLinks,
      mercurial,
      mercurialPending,
      easilysMercurial,
      easilysMercurialPending,
      mercurialLinks,
      easilysMercurialLinks,
      users,
      usersPending,
      usersLinks,
      menu,
    };
  },
};
</script>

<style lang="scss" scoped>
.col-gap {
  padding: 0.5rem;
}
</style>
