import useRules from "../security/rules";

export default async () => {
  const rules = await useRules().getRules();

  return [
    {
      visible: rules["accessDashboardBoards"],
      label: "Inventaires",
      icon: "fa fa-file-alt",
      items: [
        {
          label: "Nouveau",
          icon: "fa fa-file-circle-plus",
          to: { name: "BoardsNew" },
        },
        {
          label: "Archives",
          icon: "fa fa-file-export",
          to: { name: "BoardsList" },
        },
        { label: "Automatisations", icon: "fa fa-sync", visible: false },
      ],
    },
    {
      visible: rules["accessDashboardMercurial"],
      label: "Mercuriales",
      icon: "fa fa-list-alt",
      items: [
        {
          label: "Liquide",
          icon: "fa fa-file-alt",
          to: { name: "Mercurial" },
        },
        {
          label: "Tarifs",
          icon: "fa fa-euro-sign",
          to: { name: "Rates" },
        },
      ],
    },
    {
      visible: rules["accessDashboardMercurial"],
      label: "Mercuriales Easilys",
      icon: "fa fa-list-alt",
      items: [
        {
          label: "Liquide",
          icon: "fa fa-file-alt",
          to: { name: "EasilysMercurial" },
        },
      ],
    },
    {
      visible: rules["accessDashboardCenters"],
      label: "Etablissements",
      icon: "fa fa-building",
      items: [
        { label: "Groupes", icon: "fa fa-city", visible: false },
        {
          label: "Etablissements",
          icon: "fa fa-building",
          to: { name: "CentersList" },
        },
      ],
    },
    {
      visible: rules["accessDashboardUsers"],
      label: "Utilisateurs",
      icon: "fa fa-users",
      items: [
        { label: "Groupes", icon: "fa fa-user-friends", visible: false },
        {
          label: "Utilisateurs",
          icon: "fa fa-users",
          to: { name: "UsersList" },
        },
        {
          label: "Roles",
          icon: "fa fa-user-shield",
          to: { name: "RolesList" },
        },
      ],
    },
  ];
};
