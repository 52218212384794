<template>
  <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3">
    <BasePending v-if="loading" />
    <template v-else>
      <div
        class="context-card surface-0 shadow-2 p-3 border-1 border-50 border-round flex flex-column justify-content-between"
      >
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">{{ header }}</span>
            <div class="text-900 font-medium text-xl">
              {{ resource.length }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center border-round"
            :class="`bg-${scheme}-100`"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="text-xl" :class="[icon, `text-${scheme}-500`]"></i>
          </div>
        </div>
        <div class="flex" v-if="summary">
          <span class="text-info font-medium mr-1"
            >{{
              resource.filter((res) => res[summary.key] === summary.value)
                .length
            }}
          </span>
          <span class="text-500">{{ summary.detail }}</span>
        </div>
        <div class="links mx-1 mt-3">
          <router-link
            v-for="(link, i) in links"
            :key="i"
            custom
            v-slot="{ navigate }"
            :to="link.to"
          >
            <Button
              :label="link.label"
              @click="navigate"
              :disabled="link.disabled"
              class="p-button-outlined"
            />
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "fa fa-file-alt",
    },
    links: {
      type: Array,
      default: () => [],
    },
    scheme: {
      type: String,
      default: "blue",
    },
    resource: {
      type: [Array, Object],
      required: true,
    },
    summary: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.context-card {
  //min-height: 180px !important;
}
.links {
  display: grid;
  grid-gap: 0.3rem;
}
</style>
